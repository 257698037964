import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"   viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">



<path d="M2 9788 c1 -557 3 -993 5 -968 28 379 108 698 240 965 224 451 592
749 1113 902 165 48 415 91 620 106 25 2 -411 4 -967 5 l-1013 2 2 -1012z"/>
<path d="M8815 10793 c476 -39 821 -140 1124 -328 515 -321 797 -876 850
-1675 5 -65 9 345 10 948 l1 1062 -1012 -2 c-557 -1 -995 -3 -973 -5z"/>
<path d="M2691 8856 c-54 -30 -50 166 -51 -3061 0 -2703 2 -3003 16 -3023 39
-56 -143 -52 2594 -52 2314 0 2525 1 2556 16 69 33 64 -161 64 2334 l0 2260
-100 0 -100 0 0 -2205 0 -2205 -2415 0 -2415 0 0 2875 0 2875 2415 0 2415 0 0
-270 0 -270 100 0 100 0 0 334 0 335 -34 35 -33 36 -2544 0 c-2092 -1 -2548
-3 -2568 -14z"/>
<path d="M3140 5785 l0 -2665 1555 0 1555 0 0 2665 0 2665 -1555 0 -1555 0 0
-2665z m2820 1485 l0 -430 -430 0 -430 0 0 -1465 0 -1465 -430 0 -430 0 0
1465 0 1465 -430 0 -430 0 0 430 0 430 1290 0 1290 0 0 -430z"/>
<path d="M6683 8362 c-93 -38 -153 -130 -153 -235 0 -107 49 -185 146 -233 47
-23 63 -26 122 -22 147 11 242 110 242 253 0 78 -20 125 -75 180 -31 32 -65
55 -91 63 -57 17 -141 14 -191 -6z"/>
<path d="M6577 5718 c-3 -1462 -4 -1568 -21 -1638 -36 -152 -95 -290 -162
-381 l-34 -47 0 -236 c0 -130 4 -236 9 -236 25 0 177 114 252 189 168 169 272
364 342 641 21 85 21 89 24 1678 l3 1592 -204 0 -205 0 -4 -1562z"/>
<path d="M6780 3275 l0 -95 56 0 c48 0 59 4 85 29 67 68 13 161 -93 161 l-48
0 0 -95z m121 55 c14 -8 19 -21 19 -55 0 -57 -20 -77 -74 -73 l-41 3 -1 68 -2
67 40 0 c21 0 48 -5 59 -10z"/>
<path d="M7035 3333 c-9 -21 -24 -56 -32 -78 -9 -22 -20 -44 -25 -49 -12 -15
-10 -26 6 -26 8 0 16 10 19 23 6 20 11 22 61 19 43 -3 55 -8 59 -23 3 -10 12
-19 21 -19 18 0 21 11 7 26 -5 5 -22 43 -39 84 -34 88 -54 99 -77 43z m62 -61
c19 -19 7 -32 -31 -32 -39 0 -48 7 -35 29 5 9 14 30 20 46 l11 30 11 -30 c6
-16 16 -36 24 -43z"/>
<path d="M7155 3360 c-7 -12 16 -23 40 -18 16 3 18 -6 16 -79 -2 -69 0 -83 13
-83 12 0 16 17 18 83 l3 82 34 -3 c24 -3 32 0 29 10 -6 16 -143 24 -153 8z"/>
<path d="M7362 3327 c-10 -24 -25 -60 -31 -80 -7 -20 -17 -39 -22 -42 -14 -9
-11 -25 5 -25 8 0 16 10 19 23 6 20 11 22 61 19 44 -3 56 -8 61 -23 4 -10 13
-19 21 -19 15 0 19 12 7 24 -4 3 -21 41 -39 84 -17 42 -39 78 -47 80 -10 2
-22 -12 -35 -41z m71 -79 c-6 -12 -73 -10 -73 2 0 5 7 28 17 51 l16 42 23 -44
c12 -24 20 -47 17 -51z"/>
<path d="M4530 2150 c-86 -17 -130 -62 -130 -134 0 -67 31 -98 133 -132 98
-32 109 -40 105 -73 -3 -24 -7 -26 -58 -29 -45 -2 -66 3 -113 26 l-58 29 -19
-38 c-11 -21 -20 -41 -20 -43 0 -12 99 -54 152 -65 139 -29 250 41 235 149 -8
60 -38 86 -143 123 -85 31 -89 34 -89 62 0 28 3 30 41 33 36 3 76 -7 135 -32
12 -5 21 3 37 34 l20 41 -22 13 c-47 30 -148 48 -206 36z"/>
<path d="M2890 2105 l0 -44 68 -3 67 -3 3 -182 2 -183 55 0 55 0 0 185 0 185
70 0 70 0 0 45 0 45 -195 0 -195 0 0 -45z"/>
<path d="M3690 2105 l0 -45 81 0 81 0 -4 -130 c-3 -140 -7 -150 -58 -150 -14
0 -38 9 -55 20 -39 26 -40 26 -66 -11 l-22 -32 31 -28 c60 -54 162 -62 225
-17 50 36 57 69 57 263 l0 175 -135 0 -135 0 0 -45z"/>
<path d="M5035 2131 c-90 -40 -139 -118 -138 -217 1 -110 73 -198 186 -224 38
-9 59 -22 80 -47 36 -42 76 -63 120 -63 57 0 127 31 127 57 0 4 -8 18 -18 31
-17 22 -18 22 -59 6 -39 -16 -44 -16 -68 0 -26 17 -26 36 0 36 24 0 101 86
113 128 37 123 -12 241 -123 293 -54 25 -165 25 -220 0z m172 -91 c44 -26 66
-64 67 -113 2 -86 -51 -147 -128 -147 -52 0 -93 24 -116 70 -22 44 -25 75 -9
117 31 83 116 116 186 73z"/>
<path d="M5550 1998 c1 -159 10 -210 47 -250 83 -92 275 -82 343 19 24 35 25
44 28 210 l3 173 -55 0 -56 0 0 -151 c0 -191 -12 -218 -98 -219 -82 0 -106 60
-102 263 2 116 7 107 -55 107 l-55 0 0 -152z"/>
<path d="M6278 2068 c-66 -149 -158 -366 -158 -372 0 -3 25 -6 55 -6 55 0 55
0 71 40 l16 40 105 0 105 0 15 -40 16 -40 59 0 c32 0 58 2 58 3 0 5 -126 308
-174 420 -16 37 -17 37 -74 37 l-58 0 -36 -82z m126 -119 c14 -36 26 -71 26
-77 0 -8 -22 -12 -66 -12 -49 0 -65 3 -61 13 2 6 17 45 33 84 16 40 31 69 35
65 4 -4 19 -37 33 -73z"/>
<path d="M6777 2143 c-3 -5 -4 -107 -3 -228 l1 -220 58 -3 57 -3 0 66 0 65 48
0 49 0 34 -65 34 -65 63 0 c48 0 61 3 56 13 -5 6 -25 39 -46 72 l-37 60 28 30
c41 43 51 67 51 123 0 63 -30 109 -90 140 -39 19 -59 22 -172 22 -70 0 -129
-3 -131 -7z m261 -99 c17 -12 22 -25 22 -59 0 -59 -23 -75 -107 -75 l-63 0 0
75 0 75 63 0 c40 0 70 -5 85 -16z"/>
<path d="M7360 1920 l0 -230 186 0 185 0 -3 43 -3 42 -128 3 -128 3 3 46 3 46
95 -2 c53 -1 102 1 111 4 10 4 15 18 14 43 l0 37 -110 5 -110 5 -3 48 -3 47
125 0 126 0 0 45 0 45 -180 0 -180 0 0 -230z"/>
<path d="M10787 2003 c-24 -503 -175 -958 -422 -1278 -330 -426 -851 -665
-1560 -714 -76 -5 270 -9 938 -10 l1057 -1 0 1065 c0 586 -2 1065 -3 1065 -2
0 -7 -57 -10 -127z"/>
<path d="M2 1013 l-2 -1013 1053 2 c578 1 1027 3 997 5 -788 44 -1354 315
-1684 806 -206 307 -318 673 -359 1172 -2 22 -4 -416 -5 -972z"/>
<path d="M3427 1772 c-10 -10 -17 -25 -17 -33 0 -24 30 -59 51 -59 23 0 59 34
59 55 0 21 -36 55 -58 55 -11 0 -26 -8 -35 -18z"/>
<path d="M3497 1403 c-12 -11 -8 -163 4 -163 6 0 9 28 8 77 l-2 77 49 -3 c60
-3 63 -5 80 -47 12 -29 12 -39 0 -69 -17 -40 -43 -53 -104 -49 -25 2 -40 -1
-37 -7 4 -5 28 -9 54 -9 37 0 54 6 74 24 73 68 28 176 -73 176 -26 0 -50 -3
-53 -7z"/>
<path d="M3904 1402 c-6 -4 -18 -27 -28 -52 -10 -25 -27 -67 -37 -93 -11 -27
-14 -45 -8 -42 5 4 14 18 19 31 9 22 14 24 50 18 27 -4 40 -3 40 5 0 6 -17 11
-40 11 l-40 0 21 53 c11 28 22 53 24 55 6 6 45 -90 45 -109 0 -11 4 -19 9 -19
5 0 12 -12 15 -26 4 -15 9 -24 11 -22 5 4 -48 158 -63 185 -4 6 -12 9 -18 5z"/>
<path d="M4150 1401 c0 -5 34 -9 75 -10 43 0 75 4 75 9 0 6 -32 10 -75 10 -41
0 -75 -4 -75 -9z"/>
<path d="M4533 1393 c-4 -10 -21 -54 -40 -97 -32 -78 -38 -114 -10 -60 14 26
36 31 116 25 5 -1 12 -13 15 -27 4 -15 9 -24 12 -22 4 4 -22 80 -58 171 -12
31 -26 35 -35 10z m41 -62 c9 -24 15 -45 12 -48 -2 -2 -22 -3 -44 -1 l-39 3
20 53 c22 58 26 58 51 -7z"/>
<path d="M5042 1403 c-7 -2 -17 -13 -22 -24 -16 -28 2 -54 50 -73 36 -14 41
-20 38 -44 -3 -25 -6 -27 -50 -27 -51 0 -51 -11 -1 -21 24 -5 36 -1 53 16 33
33 23 61 -32 87 -32 15 -48 29 -48 42 0 29 25 44 62 37 25 -5 28 -4 13 3 -22
11 -44 12 -63 4z"/>
<path d="M5315 1398 c-3 -7 -4 -51 -2 -98 l2 -85 53 -3 c35 -2 52 1 52 9 0 8
-13 10 -39 6 -48 -6 -56 0 -56 41 0 30 2 32 37 32 21 0 38 4 38 9 0 5 -15 8
-32 7 -30 -1 -33 2 -39 37 l-6 37 49 0 c26 0 48 5 48 10 0 15 -100 13 -105 -2z"/>
<path d="M5617 1403 c-12 -12 -8 -183 4 -183 7 0 10 14 7 35 -4 32 -2 35 22
35 18 0 33 -12 54 -41 15 -22 30 -38 33 -35 3 3 -9 23 -26 45 -30 38 -31 40
-11 46 21 7 36 45 26 70 -10 26 -91 47 -109 28z m95 -27 c20 -29 -13 -71 -56
-71 -21 -1 -25 4 -28 42 -2 24 1 44 6 46 17 6 71 -6 78 -17z"/>
<path d="M5914 1395 c27 -79 72 -175 81 -175 11 0 91 178 83 186 -5 4 -38 -64
-70 -145 l-13 -33 -34 83 c-18 46 -38 89 -44 94 -7 7 -8 4 -3 -10z"/>
<path d="M6524 1401 c-55 -24 -72 -109 -31 -156 29 -34 87 -44 119 -20 19 15
20 16 3 11 -51 -18 -71 -17 -98 4 -25 20 -28 28 -25 73 2 41 8 54 31 71 24 18
31 19 59 8 29 -11 48 -9 36 3 -11 11 -73 14 -94 6z"/>
<path d="M6816 1401 c-3 -4 19 -9 49 -10 31 0 55 4 55 9 0 12 -97 13 -104 1z"/>
<path d="M7120 1390 c-11 -11 -20 -26 -20 -33 0 -18 29 -43 60 -52 28 -9 40
-22 40 -48 0 -24 -43 -39 -75 -27 -16 6 -25 6 -25 0 0 -14 45 -23 72 -15 28 9
38 23 38 56 0 20 -9 29 -45 47 -35 17 -45 27 -45 46 0 28 24 40 67 31 23 -4
24 -4 8 4 -32 15 -53 13 -75 -9z"/>
<path d="M6260 1310 c0 -49 4 -90 9 -90 5 0 9 41 9 90 0 50 -4 90 -9 90 -5 0
-9 -40 -9 -90z"/>
<path d="M4221 1303 c0 -46 2 -83 7 -83 4 0 6 26 4 57 -5 101 -9 111 -11 26z"/>
<path d="M6810 1310 c0 -45 4 -70 11 -70 7 0 10 12 7 30 -4 29 -3 30 34 30 21
0 38 5 38 11 0 6 -15 9 -37 7 -37 -3 -38 -2 -36 30 1 17 -3 32 -8 32 -5 0 -9
-31 -9 -70z"/>
<path d="M6816 1219 c7 -12 104 -11 104 1 0 5 -24 9 -55 9 -30 -1 -52 -6 -49
-10z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
